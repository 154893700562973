* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #090A0F;
  min-height: 100vh;
  color: #FFFFFF;
  font-size: 14px;
}

*::-webkit-scrollbar {
  display: none;
}

.tabs-active {
  border-bottom: 2px solid #FFFFFF;
}

.ml-5px {
  margin-left: 5px;
}

/* 外边距*/
.mt-3px {
  margin-top: 3px;
}

.mt-6px {
  margin-top: 6px;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-5 {
  margin-top: 50px;
}

.mt-6 {
  margin-top: 60px;
}

.mt-7 {
  margin-top: 70px;
}

.mt-8 {
  margin-top: 80px;
}

.mt-9 {
  margin-top: 90px;
}

.mt-10 {
  margin-top: 100px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mb-4 {
  margin-bottom: 40px;
}

.mb-5 {
  margin-bottom: 50px;
}

.ml-5px {
  margin-left: 5px;
}

.ml-1 {
  margin-left: 10px;
}

.ml-2 {
  margin-left: 20px;
}

.ml-3 {
  margin-left: 30px;
}

.ml-4 {
  margin-left: 40px;
}

.ml-5 {
  margin-left: 50px;
}

.mr-1 {
  margin-right: 10px;
}

.mr-2 {
  margin-right: 20px;
}

.mr-3 {
  margin-right: 30px;
}

.mr-4 {
  margin-right: 40px;
}

.mr-5 {
  margin-right: 50px;
}

.mtb-1 {
  margin: 10px 0;
}

.mtb-2 {
  margin: 20px 0;
}

.mtb-3 {
  margin: 30px 0;
}

.mtb-4 {
  margin: 40px 0;
}

.mtb-5 {
  margin: 50px 0;
}

.mlr-1 {
  margin: 0 10px;
}

.mlr-2 {
  margin: 0 20px;
}

.mlr-3 {
  margin: 0 30px;
}

.mlr-4 {
  margin: 0 40px;
}

.mlr-5 {
  margin: 0 50px;
}

.m-1 {
  margin: 10px;
}

.m-2 {
  margin: 20px;
}

.m-3 {
  margin: 30px;
}

.m-4 {
  margin: 40px;
}

.m-5 {
  margin: 50px;
}

/* 内边距 */
.pt-5px {
  padding-top: 5px;
}

.pt-1 {
  padding-top: 10px;
}

.pt-2 {
  padding-top: 20px;
}

.pt-3 {
  padding-top: 30px;
}

.pt-4 {
  padding-top: 40px;
}

.pt-5 {
  padding-top: 50px;
}

.pb-5px {
  padding-bottom: 5px;
}

.pb-1 {
  padding-bottom: 10px;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

.pb-5 {
  padding-bottom: 50px;
}

.pl-1 {
  padding-left: 10px;
}

.pl-2 {
  padding-left: 20px;
}

.pl-3 {
  padding-left: 30px;
}

.pl-4 {
  padding-left: 40px;
}

.pl-5 {
  padding-left: 50px;
}

.pr-1 {
  padding-right: 10px;
}

.pr-2 {
  padding-right: 20px;
}

.pr-3 {
  padding-right: 30px;
}

.pr-4 {
  padding-right: 40px;
}

.pr-5 {
  padding-right: 50px;
}

.ptb-1 {
  padding: 10px 0;
}

.ptb-2 {
  padding: 20px 0;
}

.ptb-3 {
  padding: 30px 0;
}

.ptb-4 {
  padding: 40px 0;
}

.ptb-5 {
  padding: 50px 0;
}

.plr-1 {
  padding: 0 10px;
}

.plr-2 {
  padding: 0 20px;
}

.plr-3 {
  padding: 0 30px;
}

.plr-4 {
  padding: 0 40px;
}

.plr-5 {
  padding: 0 50px;
}

.p-1 {
  padding: 10px;
}

.p-2 {
  padding: 20px;
}

.p-3 {
  padding: 30px;
}

.p-4 {
  padding: 40px;
}

.p-5 {
  padding: 50px;
}

.fz-14 {
  font-size: 14px;
}

.fz-22 {
  font-size: 22px;
}

.fz-20 {
  font-size: 20px;
}

.fz-10 {
  font-size: 10px;
}

.fz-16 {
  font-size: 16px;
}

.fz-24 {
  font-size: 24px;
}

.fz-18 {
  font-size: 18px;
}

.fz-12 {
  font-size: 12px;
}

.fz-36 {
  font-size: 36px;
}

.fz-wb-100 {
  font-weight: 100;
}

.fz-wb-550 {
  font-weight: 550;
}

.fz-wb-1000 {
  font-weight: 1000;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-24 {
  line-height: 24px;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.img-10 {
  width: 10px;
  height: auto;
}

.img-40 {
  width: 40px;
  height: 40px;
}

.img-18 {
  width: 18px;
  height: 18px;
}

.img-20 {
  width: 20px;
  height: 20px;
}

.img-30 {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.img-24 {
  width: 24px;
  height: 24px;
}

.img-14 {
  width: 14px;
  height: 14px;
}

.img-140 {
  width: 140px;
  height: 140px;
}

.bg-color {
  background: #0594A5;
}

.bg-white {
  background-color: #FFFFFF;
}

.color {
  color: #FFFFFF
}

.color-sub {
  color: rgba(69, 90, 100, 0.5);
}

.color-main {
  color: #587FED
}

.color-yellow {
  color: #FAC81E
}

.none {
  display: none;
}

.block {
  display: block;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.align-items {
  align-items: center;
}

.text-space {
  letter-spacing: 5px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.row {
  display: flex;
}

.row-items-center {
  display: flex;
  align-items: center
}

.row-justify-center {
  display: flex;
  justify-content: center
}

.row-justify-between {
  display: flex;
  justify-content: space-between
}

.row-justify-around {
  display: flex;
  justify-content: space-around
}

.row-justify-evenly {
  display: flex;
  justify-content: space-evenly
}

.row-justify-end {
  display: flex;
  justify-content: flex-end
}

.row-justify-start {
  display: flex;
  justify-content: flex-start
}

.row-center {
  display: flex;
  justify-content: center;
  align-items: center
}

.column {
  display: flex;
  ;
  flex-direction: column
}

;

.column-items-center {
  display: flex;
  align-items: center;
  flex-direction: column
}

.column-justify-center {
  display: flex;
  justify-content: center;
  flex-direction: column
}

;

.column-justify-between {
  display: flex;
  justify-content: space-between;
  flex-direction: column
}

.column-justify-around {
  display: flex;
  justify-content: space-around;
  flex-direction: column
}

.column-justify-evenly {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column
}

.column-justify-end {
  display: flex;
  justify-content: flex-end;
  flex-direction: column
}

.column-justify-start {
  display: flex;
  justify-content: flex-start;
  flex-direction: column
}

.column-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column
}