.default-button {
  width: 100%;
  height: 51px;
  background: #587FED;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.navbar {
  width: 100%;
  height: 44px;
  background: #090A0F;
  border-radius: 0px 0px 0px 0px;
  position: fixed;
  top: 43px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.navbar-block {
  height: 43px;
  display: block;
}

.connect-wallet-button {
  width: 100px;
  height: 20px;
  background: #587FED;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  font-size: 10px;
  color: #fff;
}

.text-hidden-90 {
  width: 85px;
  height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rv-popup__title {
  color: #455A64;
  font-size: 20px;
  font-weight: bold;
}

.InsufficientBalanceModal-bg-box {
  width: 100%;
  height: 51px;
  background: rgba(88, 127, 237, 0.2);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
}

.modal-solid {
  width: 100%;
  background-color: #D8D8D8;
  height: 1px;
}

.ConvertDividendsModal-inActive {
  width: 18px;
  height: 18px;
  opacity: 1;
  border: 2px solid #587FED;
  border-radius: 9px;
}

.ConvertDividendsModal-active {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #587FED;
  opacity: 1;
}

.BlindboxWidget-box-img {
  width: 100%;
  height: 217px;
  background-size: 100% 100%;
  background-image: url('../static/home/box.png');
  position: relative;
}

.BlindboxWidget-box-abs {
  top: 93px;
  left: 227px;
  position: absolute;
}

.BlindboxWidget-buy-button {
  width: 149px;
  height: 24px;
  background: linear-gradient(135deg, transparent 5px, #587FED 0) top left,
    linear-gradient(-45deg, transparent 5px, #587FED 0) top right;
  background-size: 50.3% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 0;
}

.BlindboxWidget-title-abs {
  position: absolute;
  left: 223px;
  top: 44px;
  width: 149px;
  height: 23px;
}