.layout-header {
  width: 100%;
  height: 43px;
  background-color: #090A0F;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.layout-header-block {
  display: block;
  height: 44px;
}

.layout-pages {
  width: 100%;
  min-height: 70vh;
  background-color: #090A0F;
  overflow: hidden;
  overflow-y: scroll;
}

.layout-popup-fixed-shade {
  width: 100vw;
  position: fixed;
  height: calc(100vh - 44px);
  background-color: rgba(0, 0, 0, 0);
  top: 44px;
  left: 0;
  z-index: 9;
}

.layout-popup-fixed-show {
  top: 44px;
  position: fixed;
  background-color: #fff;
  width: 270px;
  height: calc(100vh - 44px);
  transition: all .25s linear;
  overflow-y: scroll;
  padding: 20px;
  right: 0;
  z-index: 10;
}

.layout-popup-fixed-close {
  top: 44px;
  position: fixed;
  background-color: #fff;
  width: 270px;
  height: calc(100vh - 44px);
  transform: translateX(110vw);
  transition: all .25s linear;
  overflow-y: scroll;
  padding: 20px;
  right: 0;
}

.layout-logo-96x28 {
  width: 96px;
  height: 28px;
  object-fit: cover;
}

.layout-wallet-button {
  width: 82px;
  height: 20px;
  background: #587FED;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  font-size: 10px;
  color: #fff;
}

.layout-header-slice-solid {
  height: 44px !important;
  margin: 0px 10px !important;
}

.w-338px {
  width: 338px;
}